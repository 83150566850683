  #store-search{
    position: relative;
    width: 45px;
    float: right;
    transition: width 200ms;
  }

  #store-search.searching{
    width:100%;
  }

    .store-search-icon{
      position: absolute;
      top: 0;
      left: 0;
      width: 35px;
      padding-left: 0px;
      padding-right: 5px;
    }

    .store-search-input {
      padding:0.6em;
      padding-left: 30px;
      background-color: black;
    }

      .store-search-input input{
        font-size:1em;
        text-align: right;
        max-width: 86%;
        border:0;
        outline: none;
        background: transparent;
        color:white;
        letter-spacing: 0.3em;
      }

/* STORE */
.store-content{}

  .store-product{
    float:left;
    width:25%;
    position: relative;
  }

  .store-product:nth-of-type(4n+1){
    clear:left;
  }

  .store-product-out-of-stock{
    position:absolute;
    top:1em;
    right:7%;
    background-color:#D63737;
    font-size: 0.5em;
  }

  .store-product-digital{
    position: absolute;
    top: 1em;
    left: -1%;
    /*background-color: rgb(39, 38, 38);*/
    font-size: 0.7em;
  }

    .store-thumbnail{
      margin:0 8% 8% 0;
      height:0;
      padding-bottom:94%;
    }

    .store-product.active .store-thumbnail{
      opacity: 0.7;
    }

    .store-product.loading .store-thumbnail{
      opacity: 0.2;
    }

  .product-wrapper{
    width: 400%;
    overflow: hidden;
    margin: 0;
    margin-top: 8%;
    max-height:0;
    transition: max-height 0.1s;
  }

  .store-product.active:not(.loading) .product-wrapper{
    transition: max-height .5s ease-in-out; /*make sure this is the same as scroll animation*/
    max-height:1000px;
  }

  .store-product:nth-of-type(4n+2) .product-wrapper{
    margin-left: -100%;
  }

  .store-product:nth-of-type(4n+3) .product-wrapper{
    margin-left: -200%;
  }

  .store-product:nth-of-type(4n+4) .product-wrapper{
    margin-left: -300%;
  }
