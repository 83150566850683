/* login / reset */
.account-login-dialog{
  width: 50%;
  margin: auto;
  padding: 1.5em;
  background: rgba(255, 255, 255, 0.33);
  text-align: center;
}

.account-login-dialog input:not([type=radio]):not([type=checkbox]),
.account-login-dialog textarea,
.account-login-dialog label
{
  width:100%;
  padding-right:1em;
  box-sizing: border-box;
}

.account-login-dialog label{
  display:block;
  margin-bottom:0.4em;
}

/*nothing to see*/
.nothing-to-see{
  width:100%;
  text-align: center;
}

/* Orders */
.account-orders{
}

  .account-order{border:1px solid black}

.account-address{
}
  .account-address-billing{}
  .account-address-shipping{}

.account-details{
}

/*Order*/

.account-orders{
  display:table;
  width:100%;
  border-collapse: separate;
  border-spacing: 0 1.5em;
  margin-top: -1.5em;
}
.account-order{
  display:table-row;
  background:white;
}
.account-order-cell{
  display:table-cell;
  text-align:center;
  vertical-align: middle;
  width: 1%;
  white-space: nowrap;
}

.account-order-number{
  letter-spacing: 0.3em;
}

  .account-order-label{
    line-height: 2em;
    font-size: 0.8em;
    opacity: 0.5;
    font-weight:bold;
    padding-top: 5%;
  }

  .account-order-value{
    line-height: 2em;
    padding-bottom:5%;
  }

/* Download */
.account-downloads{
  float:left;
  width:100%;
  box-sizing:border-box;
  display:table;
  border-collapse: separate;
  border-spacing: 0 1.5em;
  margin-top: -1.5em;
}

.account-download-expired .account-download-image{
  opacity: 0.4;
  pointer-events: none;
}

.account-download-row{
  display: table-row;
  background: rgba(255, 255, 255, 0.52);
}

.account-download-cell{
  display:table-cell;
  vertical-align: top;
  padding: 0.5em;
}

.account-download-image-cell{
  width:1%;
}
.account-download-download-cell{
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
}
.account-download-download-cell .label{
  background:none;
}

.account-download-image{
  width:10em;
  height:10em;
}

.account-download-title{
  float:left;
}

.account-download-line{
  float:left;
  margin: 0.9em 0.9em 0 0;
  font-size:0.7em;
}

.account-download-link{
  font-size:0.7em;
}

.account-download-line:first-of-type,
.account-download-order{
  clear:left;
}

/*Single Order*/
.order-section{
  float:left;
  box-sizing: border-box;
  padding:0.5em;
}

.order-section-full-width{
  width:100%;
}
.order-section-half-width{
  width:50%;
}

.order-section .label{
  font-size:0.8em;
}

.label.order-address-line{
  display:table;
}

.order-items{
  display:table;
  border-collapse: separate;
  border-spacing: 0 1.5em;
  margin-top: -1.5em;
}

.order-item{
  display:table-row;
}

.order-item-cell{
  display:table-cell;
  vertical-align: middle;
}

.order-item-cell-center{
  text-align: center;
}

.order-item-cell-right{
  text-align:right;
}

.order-item-image{
  width:2em;
  height:2em;
 }
