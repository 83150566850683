#menu{
  background: #acc;
}

#menu:before{
  background:white;
}

  .menu-item{
    float:left;
    width:25%;
    font-size: 0.9em;
  }

  .menu-item h2{
    text-align: center;
    padding:5px;
    letter-spacing: 0.3em;
  }

  .menu-item:first-child h2{
    font-weight:700;
  }

  .menu-item-content{
    position:relative;
    margin:6%;
    display:none;
  }

  .menu-item-content.expand{
    display:block;
  }

  .menu-item .label{
    margin: 0 2.5% 5% 2.5%;
  }

/* Navigation */
#menu-navigation ul li{
  /*margin-bottom: 7%;*/
  text-align: right;
}

/* Player */

#record-player{
  width:80%;
  height:0;
  padding-bottom:80%;
  position: relative;
  margin:auto;
}

.record-playing{
  -webkit-animation: spin_animation 3s infinite linear; /*0.8 would be accurate*/
  animation: spin_animation 3s infinite linear;
}

.centered-cirle{
  border-radius: 100%;
  position:absolute;
  top:50%;
  left:50%;
  overflow: hidden;
}

  #record-outer{
    background: black;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 50%;
  }

    #record-track{
      background:rgba(255,255,255,0.11);
      width:94%;
      height:94%;
      margin: -47% 0 0 -47%;
    }

    #record-inner{
      background:black;
      width:40%;
      height:40%;
      margin: -20% 0 0 -20%;
    }

    #record-label{
      background:#FFF6F6;
      width:34%;
      height:34%;
      margin: -17% 0 0 -17%;
    }

    #record-player-spindle{
      background:black;
      width:4px;
      height:4px;
      margin: -2px 0 0 -2px;
    }

    #record-player-arm-pivot{
      position:absolute;
      top:10%;
      right:0%;
      transition: transform 0.2s;
      /*border: 10px solid #A0A0A0;
      border-radius: 10px;*/
    }

    #record-player-arm-pivot:after{
      content:':3';
      color:rgba(0,0,0,0);
      position:absolute;
      border: 10px solid #A0A0A0;
      border-radius: 10px;
      top:-10px;
      left:-10px;
      height:0;
      width:0;
    }

    #record-player-arm{
      cursor: pointer;
      width:8px;
      height:120px;
      background: grey;
      position:absolute;
      top:-20px;
      left:-4px;
      z-index:1;
    }

    #record-player-arm:after{
      content: ':3';
      color: rgba(0,0,0,0);
      position: absolute;
      top: 100%;
      right: 0;
      width: 100%;
      height: 25px;
      background: grey;
      transform: rotateZ(0.6rad);
      transform-origin: top right;
    }

    #record-player-ctrl{
      position:absolute;
      bottom:0;
      right:0;
      font-size:1.2em;
      padding-left:1px;/* stops icon being cut */
    }

/* Account */
#menu-account{
  text-align: center;
}
.menu-account-signin input{
  width:90%;
}

.menu-account-details{
  margin-bottom:1em;
  text-align:left;
}

.menu-account-avatar{
  width:3em;
  height:3em;
  float:right;
  background: blue;
  margin:0 1em 1em 0;
  display:none;
}

.menu-account-tiny{
  font-size: 0.5em

}
.menu-account-tinier{
  font-size: 7px;
}

.menu-account-name{
  width:90%;
  margin-left:0!important;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menu-account-email{
  display:none;
}

.menu-account-orders{
  display:inline-block;
  float:left;
}

.menu-account-downloads
{
  display:inline-block;
  float:right;
}

.menu-account-orders span,
.menu-account-downloads span,
.menu-account-orders a,
.menu-account-downloads a
{
  margin:0!important;
  display:inline-block;
}

.menu-account-buttons{
}

.menu-account-view{
}

.menu-account-signout{
}

/* Cart */
#menu-cart .label{
  /*display:block;*/
}

#menu-cart .label:before{
  /*content:':3';
  color:rgba(0,0,0,0);
  display:block;*/
}

.menu-cart-items-count{
  font-size:0.7em;
  background: #D63737;
  border-radius: 10em;
  line-height: 0.4em;
  padding: 0.4em 0.4em 0.5em 0.4em;
  display:inline-block;
  color:white;
  position:relative;
  top:-0.8em;
  left:-0.6em;
  letter-spacing: normal;
  text-align:center;
}

.menu-cart-item{
  position: relative;
  width:90%;
  margin: 0!important;
  margin-bottom: 5%!important;
}

.menu-cart-tiny{
  font-size: 0.6em;
  line-height: 1.8em;
}

.menu-cart-empty{
  text-align: center;
}

.menu-cart-full{
  line-height: 0em;
  text-align: center;
}

  .menu-cart-item-image{
    position:absolute;
    top:0;
    left:0;
    width:20%;
    height:100%;
  }

  .menu-cart-item-title{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-left: 20%;
  }

  .menu-cart-item-title a{
    color:white;
    text-decoration:none;
  }

  .menu-cart-item-price{
    letter-spacing: 0.1em;
    float:right;
    font-weight:bold;
  }
  .menu-cart-buttons{
    text-align:center;
  }
/*
#menu-cart-cart{float:left;}
#menu-cart-checkout{float:right;}*/
