body{
  font-family: 'Source Sans Pro', sans-serif;

  /*ASH*/
  /*background: #606c88;
  background: -webkit-linear-gradient(to left, #606c88 , #3f4c6b);
  background: linear-gradient(to left, #606c88 , #3f4c6b);*/

  /*ALMOST*/
  /*background: #ddd6f3;
  background: -webkit-linear-gradient(to left, #ddd6f3 , #faaca8);
  background: linear-gradient(to left, #ddd6f3 , #faaca8);*/

  /*my grey*/
  background: #EAEAEA;
  background: -webkit-linear-gradient(to left, #FFFFFF , #D3D8DE);
  background: linear-gradient(to left, #FFFFFF , #D3D8DE);

  /*FACEBOOK MESSENGER*/
  /*background: #00c6ff;
  background: -webkit-linear-gradient(to left, #00c6ff , #0072ff);
  background: linear-gradient(to left, #00c6ff , #0072ff);*/

  /*LITTLE LEAF*/
  /*background: #76b852;
  background: -webkit-linear-gradient(to left, #76b852 , #8DC26F);
  background: linear-gradient(to left, #76b852 , #8DC26F);*/
}

h1{
  font-size: 2.6em;
}

h2{
  font-size: 1.3em;
}

p{
  margin:1em 0;
  font-weight:300;
}

a{
  text-decoration:none;
  color:white;
}

strong{
  font-weight:bold;
}

button{
  font-size:0.9em;
}

.disabled{
  cursor: default;
  pointer-events: none;
  opacity: 0.5;
}

.text-input{
  border: none;
  outline: none;
  font-size: 1em;
  padding: 0.6em;
  margin-bottom:0.9em;
}

.select-input{
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  outline:none;
  border: 0px;
  border-radius: 0px;
  -webkit-appearance: none;
}

/* Layout */

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.page-width{
  width:90%;
  max-width:1000px;
  margin:auto;
}

.left-width{
  float:left;
  width:20%;
}

.right-width{
  float:right;
  width:80%;
}

/* Design Constants */

#bg{
  position:fixed;
  top:0px;
  left:0px;
  height:100%;
  width:100%;
  margin:0;
  padding:0;
  z-index: -9999;
}

.button{
  cursor:pointer;
}

.label{
  padding:10px;
  background:rgb(10,10,10);
  color:rgb(250,250,250);
  display: inline-block;
  text-decoration: none;
  letter-spacing: 0.3em;
  text-indent: 0.3em;
  border:none;
  margin:0;
}

.black{
  background:rgb(10,10,10);
  color:rgb(250,250,250);
}

.white{
  background:rgb(250,250,250);
  color:black;
}

.cover-center{
  background-size:cover;
  background-position: center;
}

/* Menu Bar */

#menu{
  width:100%;
  z-index: 10;
  position:fixed;
  bottom:0;
  left:0;
}

  #menu:before, body:after{
    content: ":3";
    color:rgba(0,0,0,0);
    z-index: -1;
    display: block;
    position:absolute;
    width:100%;
    font-size: 1.3em;
    padding:5px 0;
  }

/* Top Bar */

#top{
  position:relative;
}

  .back-button{
    text-indent: 0.1em;
  }

  .breadcrumb, .title{
    margin-left:10px;
    margin:2% 2% 2% 0;;
    display:inline-block;
  }

  .left-breadcumb{
    float:right;
    margin-left:10px;
    margin:8%;
    display:inline-block;
    line-height: 2em;
  }

  .title{}

/* Content */

#content{
  position:relative;
}
