.cart-item-height{
  height:3em;
  line-height: 3em;
  margin-bottom: 1em;
}

.cart-item-image{
  /*height:3em;*/
  width:3em;
  padding:0;
  margin:0 8% 8% 0;
  margin:0 1em 1em 0;
  float:right;
}
.cart-item-details{
  box-sizing: border-box;
  width:100%;
  background-color: white;
  letter-spacing: 0.15em;
}

.cart-item-totals{
  letter-spacing: 0.15em;
}

  .cart-item-block{
    float:left;
    display:inline-block;
    margin:0 1em;
  }

  a.cart-remove{
    text-decoration: none;
    color:#CE0505;
  }

  .cart-item-qty{
    float:right;
  }
  .cart-item-qty input{
    position: relative;
    border-bottom:2px solid grey;
    width:30px;
    text-align: center;
    padding: 0.2em;
    cursor: pointer;
  }

.cart-item-unit-price,
.cart-item-price{
  float:right;
  width: 5em;
  text-align: right;
}

.cart-item-price{
  font-weight: bold;
}

.cart-empty{
  text-align: center;
}

.cart-totals-name{
  float:right;
  font-weight: bold;
}

.cart-continue{float:left}

.cart-checkout{float:right;}
