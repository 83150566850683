.product-container{
  position:relative;
  float:left;
  margin-bottom: 2%;
  background-color:rgb(20,20,20);
  width:96%;
  color:white;
  display: flex;
  padding: 2% 0 0 2%;
}

  .product-container > div, .product-info > div{
    margin:0 2% 2% 0;
  }

  .product-video{
    margin-bottom:0;
    padding-bottom:54%;
    background:rgba(255,255,255,0.2);
    position:relative;
  }

  .product-video iframe{
    position: absolute;
    width:100%;
    height:100%;
  }

  .product-images{
    float:left;
    width:28%;
  }

  .product-image{
    padding-bottom:100%;
    background-color:grey;
    margin-bottom: 7%;
  }

  .product-thumbnail{
    width:28.6666666%;
    margin:0 7% 7% 0;
    padding-bottom:28.6666666%;
    background-color:grey;
    float:left;
  }

  .product-thumbnail:nth-of-type(3n){
    margin-right:0;
  }

  .product-tiny{
    letter-spacing:0.2em;
    font-size:0.8em;
  }

  .product-info{
    display:table-cell;
    flex:1;
  }

  .product-price-add, .product-options{}

  .product-options{
    /*margin-top:0!important;*/
  }

  .product-options-select{
    display:inline-block;
    margin-right:0.5em;
    width:auto;
    height:auto;
  }

    .product-prices{
      float:right;
    }

      .product-price{}

      .product-sold-out{
        background-color:#CA3838;
        /*color:*/
      }

    .product-add{}

  .product-player{}
  .product-player-time{
    letter-spacing: 0.3em;
  }

  .product-title{
    letter-spacing: 0.3em;
  }

  .product-description{
    clear:both;
  }
