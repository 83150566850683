.notices{
  position: fixed;
  margin: auto;
  width: 100%;
  top: 0;
  right: 0;
  width: 0;
  white-space: nowrap;
}

  .notice{
    /*width: auto;
    margin: auto;
    display: table;
    max-width: 50%;
    margin-bottom: 1em;*/
    padding: 1em;
    float:right;
    clear:right;
    margin-top: 1em;
    /*text-align: center;*/
    /*border: 1px solid white;*/
  }

  .notice a{
    color:#191919;
    text-decoration: underline;
  }

  .notice-errors{
    background:#EC6565;
  }

  .notice-notice{
    background:#ECECEC;
  }

  .notice-success{
    background: #93EA93;
  }
