h3.checkout-form-heading{
  margin-bottom:1.5em;
}

.checkout-totals-table{
  display:table;
  width:100%;
}

.checkout-totals-row{
  display: table-row;
  line-height:1.6em;
}

.checkout-totals-cell{
  display: table-cell;
}

.checkout-totals-cell-right{
  display: table-cell;
  text-align:right;
}

.checkout-item-headers{
  font-weight: bold;
}

.checkout-item{

}
.checkout-subtotal{
  font-weight: bold;
}
.checkout-totals label{
  text-decoration: underline;
  margin-top:1em;
}
.checkout-shipping{

}
.checkout-shipping-total{
  font-weight: bold;
}
.checkout-total{
  font-weight: bold;
}

.checkout-payment-method{
  /*background: grey;*/
}

.checkout-process{
  margin:auto;
  display:block
}


 /* FORM */
.checkout-input{
  box-sizing:border-box;
  padding:0 1em 1em 0;
}

.checkout-input input:not([type=radio]):not([type=checkbox]),
.checkout-input textarea,
.checkout-input label,
.checkout-input select,
.checkout-input .form-radio-field
{
  width:100%;
  padding-right:1em;
  box-sizing: border-box;
}

.checkout-input.field-full-width{
  float:left;
  width:100%
}

.checkout-input.field-half-width{
  float:left;
  width:50%;
}

  .checkout-input label{
    display:block;
    margin-bottom:0.4em;
  }

  .checkout-input .text-input{
    margin-bottom:0;
  }

.checkout-forms{
  width:62%;
  float:left;
}

.checkout-totals{
  width:38%;
  float:right;
  border:2px solid white;
  box-sizing: border-box;
  padding:1.5em;
}
